import activateMiami from 'assets/images/activate-miami.jpg'
import Faq from 'components/faq/Faq'
import Section from 'components/section/Section'
import { FAQS_MIAMI } from 'constants/faqs'
import { useState } from 'react'
import { Helmet } from 'react-helmet-async'
import './faq-miami.scss'

const FAQMiami = () => {
  const [selected, setSelected] = useState();

  const handleOnSelectedFaq = (question) => setSelected(question);
  
  return (
    <div className="faq">
      <Helmet>
          <title>FAQs - Activate x Wormhole Miami</title>
          <link rel="canonical" href="http://www.activate.build/miami/faq" />
          <meta name="description" content="FAQs about Activate miami hackathon event" />
          <meta property="og:description" content="FAQs about Activate miami hackathon event" />
          <meta property="og:image" content={activateMiami} />
          <meta property="og:title" content="FAQs - Activate x Wormhole Miami" />
          <meta property="og:url" content="http://www.activate.build/miami/faq" />
          <meta property="twitter:title" content="FAQs - Activate x Wormhole Miami" />
          <meta property="twitter:description" content="FAQs about Activate miami hackathon event" />
      </Helmet>
      <Section type="columns">
        <h1>Useful Resources</h1>
        <p className="sub-text">
          Activate is a global organization of web3 builders fostering a multi-chain and fully inclusive industry.
        </p>
        {FAQS_MIAMI.map(question => <Faq onClick={handleOnSelectedFaq} open={question.question === selected} key={question.question} question={question.question}>{question.answer}</Faq>)}
      </Section>
    </div>
  );
};

export default FAQMiami;
