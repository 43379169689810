const Track = ({ image, title, children }) => {
  return (
    <div className="track">
      <img src={image} alt={title} />
      <h4>{title}</h4>
      {!!children && <p>{children}</p>}
    </div>
  );
};

export default Track;
