import defi from 'assets/images/tracks/defi.svg'
import infrastructure from 'assets/images/tracks/infrastructure.svg'
import metaverse from 'assets/images/tracks/metaverse.svg'
import nfts from 'assets/images/tracks/nfts.svg'

export const TRACKS = [
  {image: defi, title: 'DeFi', content: 'Create innovative solutions that disrupt the tradicional financial system'},
  {image: infrastructure, title: 'Infrastructure', content: 'Build solutions to scale blockchains and protocols'},
  {image: metaverse, title: 'Metaverse', content: 'Take part in creating the future of virtual worlds and gaming'},
  {image: nfts, title: 'NFTs', content: 'More than JPEGs. Bring creators and developers together to build'}
];
