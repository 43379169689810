import activateLogo from 'assets/images/activate-logo.svg'
import Footer from 'components/footer/Footer'
import Header from 'components/header/Header'
import usePageTracking from 'hooks/usePageTracking'
import FAQMiami from 'pages/landings/miami/faq/FAQMiami'
import LandingMiami from 'pages/landings/miami/LandingMiami'
import PrizesMiami from 'pages/landings/miami/prizes/PrizesMiami'
import { Helmet } from 'react-helmet-async'
import { Navigate, Route, Routes, useMatch } from 'react-router-dom'

function App() {
  usePageTracking();
  let match = useMatch({ path: '/miami', end: true });
  return (
    <div className="App">
      <Header showHero={!!match} />
      <Helmet>
          <meta charSet="utf-8" />
          <title>Activate</title>
          <meta name="description" content="Activate is a global organization of web3 builders fostering a multi-chain and fully inclusive industry." />
          <meta property="og:description" content="Activate is a global organization of web3 builders fostering a multi-chain and fully inclusive industry." />
          <link rel="canonical" href="http://www.activate.build" />
          <meta property="og:locale" content="en_US" />
          <meta property="og:site_name" content="Activate" />
          <meta property="og:image" content={activateLogo} />
          <meta property="twitter:card" content="summary_large_image" />
      </Helmet>
        <Routes>
          <Route
            path="/"
            element={<Navigate to="/miami" replace />}
          />
          <Route path="/miami">
            <Route index element={<LandingMiami />} />
            <Route path="faq" element={<FAQMiami />} />
            <Route path="prizes" element={<PrizesMiami />} />
          </Route>
          <Route
            path="*"
            element={<Navigate to="/" replace />}
          />
        </Routes>
      <Footer />
    </div>
  );
}

export default App;
