import {
  Link,
  useMatch,
  useResolvedPath
} from 'react-router-dom'
import './nav-link.scss'

const isValidHttpUrl = (string) => {
  try {
    const url = new URL(string);
    return url.protocol === "http:" || url.protocol === "https:";
  } catch (_) {
    return false;  
  }
}

const NavLink = ({ children, to, ...props }) => {
  const isExternal = isValidHttpUrl(to); 
  const Component = isExternal ? 'a' : Link
  const linkProps = {
    [isExternal ? 'href' : 'to']: to
  };
  const resolved = useResolvedPath(to);
  const match = useMatch({ path: resolved.pathname, end: true });

  return (
    <Component
      className={`nav-link${match ? ' active' : ''}`}
      {...linkProps}
      {...props}
    >
      {children}
    </Component>
  );
}

export default NavLink;
