import './judges.scss'

const Judges = ({ judges }) => {
  return (
    <div className="judges">
      <div className="header-title">
        <h2>
          Mentors and Judges
        </h2>
      </div>
      <div className="judge-list">
        {judges.map(judge => (
          <div key={judge.name} className="judge">
            <div className="judge-image">
              <img alt={judge.name} src={judge.photo} height="130" width="130" />
            </div>
            <h4>{judge.name}</h4>
            <h5>{judge.title}</h5>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Judges;
