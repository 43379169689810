import NavLink from 'components/navLink/NavLink'
import { useEffect, useRef, useState } from 'react'
import './app-menu.scss'

const AppMenu = () => {
  const [showMenu, setShowMenu] = useState(false);
  const navRef = useRef();
  const handleOnMenuClick = () => {
    setShowMenu(prev => !prev);
  };
  const handleOnMenuLinkClick = () => {
    setShowMenu(false);
  }
  useEffect(() => {
    const outerMenuClick = (ev) => {
      if (!ev.composedPath().includes(navRef.current)) {
        setShowMenu(false);
      }
    } 
    document.addEventListener('click', outerMenuClick);
    return () => document.removeEventListener('click', outerMenuClick);
  }, [setShowMenu]);
  return (
    <div ref={navRef} className="app-menu">
      <button className={`menu-button mobile ${showMenu ? 'open' : 'closed'}`} onClick={handleOnMenuClick}>
        <div></div>
      </button>
      <nav className={`page-nav desktop ${showMenu ? 'open' : 'closed'}`}>
        <NavLink onClick={handleOnMenuLinkClick} to="/miami">Miami</NavLink>
        <NavLink to="https://activate-miami.notion.site/d6fbf56f706a4c1883c116338af2886b?v=27a2840009834de9b5da2fb05ef59022" target="_blank" rel="noreferrer">
          Schedule
        </NavLink>
        <NavLink onClick={handleOnMenuLinkClick} to="/miami/prizes">Prizes</NavLink>
        <NavLink to="https://www.notion.so/activate-miami/Bounties-72425e1ed6e249f6934bba4e7b884b6a" target="_blank" rel="noreferrer">
          Bounties
        </NavLink>
        <NavLink onClick={handleOnMenuLinkClick} to="/miami/faq">FAQs</NavLink>
      </nav>
    </div>
  );
};

export default AppMenu;
