import { useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'

const TOP = 0
const LEFT = 0

const ScrollToTop = ({ children }) => {
  const location = useLocation();
  const prevLocation = useRef({ pathname: undefined });

  useEffect(() => {
    if (location.pathname !== prevLocation.current.pathname) {
      prevLocation.current = location;
      window.scrollTo(LEFT, TOP);
    }
  }, [location]);

  return children;
};

export default ScrollToTop;

