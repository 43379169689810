import { useEffect, useRef } from "react"
import { useLocation } from "react-router-dom"

const usePageTracking = () => {
  const location = useLocation();
  const prevPageView = useRef();
  useEffect(() => {
    if (window.gtag && (!prevPageView.current || prevPageView.current !== location.pathname + location.search)) {
      window.gtag('set', 'page_path', location.pathname + location.search);
      window.gtag('event', 'page_view');
      prevPageView.current = location.pathname + location.search;
    }
  }, [location.pathname, location.search]);
};

export default usePageTracking;