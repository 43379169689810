import activateLogo from 'assets/images/activate-logo.svg'
import v305ventures from 'assets/images/brands/305ventures.svg'
import algorand from 'assets/images/brands/algorand.svg'
import blu from 'assets/images/brands/blu.png'
import c3 from 'assets/images/brands/c3.svg'
import celo from 'assets/images/brands/celo.svg'
import coinbase from 'assets/images/brands/coinbase.svg'
import developerDao from 'assets/images/brands/developer-dao.svg'
import digitalCurrencyGroup from 'assets/images/brands/digital-currency-group.svg'
import dorahacks from 'assets/images/brands/dorahacks.svg'
import dropoutdao from 'assets/images/brands/dropoutdao.webp'
import ethereum from 'assets/images/brands/ethereum.svg'
import figment from 'assets/images/brands/figment.webp'
import gnosis from 'assets/images/brands/gnosis.svg'
import hbar from 'assets/images/brands/hbar.svg'
// import hedera from 'assets/images/brands/hedera.svg'
import herdao from 'assets/images/brands/herdao.svg'
import ironHack from 'assets/images/brands/iron-hack.svg'
import ledger from 'assets/images/brands/ledger.svg'
import lightshift from 'assets/images/brands/lightshift.svg'
import lukka from 'assets/images/brands/lukka.svg'
import mdc from 'assets/images/brands/mdc.svg'
import miamiHackWeek from 'assets/images/brands/miami-hack-week.svg'
import minority from 'assets/images/brands/minority.svg'
import near from 'assets/images/brands/near.svg'
import oraichain from 'assets/images/brands/oraichain.svg'
import polygon from 'assets/images/brands/polygon.svg'
import principle from 'assets/images/brands/principle.svg'
import quicknode from 'assets/images/brands/quicknode.svg'
import skynet from 'assets/images/brands/skynet.svg'
import solana from 'assets/images/brands/solana.svg'
import starknet from 'assets/images/brands/starknet.svg'
import storj from 'assets/images/brands/storj.svg'
import thegraph from 'assets/images/brands/thegraph.svg'
import transak from 'assets/images/brands/transak.svg'
import web3familia from 'assets/images/brands/web3familia.png'
import wiw3 from 'assets/images/brands/wiw3.webp'
import wormhole from 'assets/images/brands/wormhole.svg'

export const ECOSYSTEMS_SPONSORS = [
  { name: 'Wormhole', url: 'https://wormholenetwork.com', picture: wormhole},
  { name: 'Ethereum', url: 'https://ethereum.org', picture: ethereum},
  { name: 'Solana', url: 'https://solana.com', picture: solana},
  { name: 'Polygon', url: 'https://polygon.technology', picture: polygon},
  { name: 'StarkNet', url: 'https://starknet.io', picture: starknet},
  { name: 'Near Protocol', url: 'https://near.org', picture: near},
  { name: 'Celo', url: 'https://celo.org', picture: celo},
  { name: 'Algorand', url: 'https://www.algorand.com', picture: algorand},
  { name: 'Gnosis Chain', url: 'https://www.xdaichain.com', picture: gnosis},
  { name: 'Digital Currency Group', url: 'https://dcg.co', picture: digitalCurrencyGroup},
  { name: 'Skynet', url: 'https://skynetlabs.com', picture: skynet},
  { name: 'Transak', url: 'https://transak.com', picture: transak},
  { name: 'Lukka', url: 'https://lukka.tech', picture: lukka},
  { name: 'The HBAR Foundation', url: 'https://www.hbarfoundation.org', picture: hbar},
  { name: 'Ledger', url: 'https://www.ledger.com', picture: ledger},
  { name: 'C3', url: 'https://c3.io', picture: c3},
  { name: 'Coinbase wallet', url: 'https://www.coinbase.com/wallet', picture: coinbase},
  { name: 'Figment', url: 'https://www.figment.io', picture: figment},
  { name: 'Oraichain', url: 'https://orai.io', picture: oraichain},
  { name: 'Quicknode', url: 'https://www.quicknode.com', picture: quicknode},
  { name: 'Storj', url: 'https://www.storj.io', picture: storj},
  { name: 'The Graph', url: 'https://thegraph.com', picture: thegraph},
  // { name: 'Hedera', url: 'https://hedera.com', picture: hedera},
];

export const MAIN_ORGANIZERS = [
  { name: 'Activate', url: '', picture: activateLogo},
  { name: 'Lightshift Capital', url: 'https://www.lightshift.capital', picture: lightshift},
  { name: 'Miami Dade College', url: 'https://www.mdc.edu', picture: mdc},
];

export const SUPPORTING_ORGANIZERS = [
  { name: 'DoraHacks', url: 'https://dorahacks.io/', picture: dorahacks},
  { name: 'H.E.R. DAO', url: 'https://twitter.com/_herdao', picture: herdao},
  { name: '305 Ventures', url: '', picture: v305ventures },
];

export const PARTNERS = [
  { name: 'Developer DAO', url: 'https://www.developerdao.com', picture: developerDao},
  { name: 'Minority Programmers Association', url: 'https://www.minorityprogrammers.com', picture: minority},
  { name: 'Dropout DAO', url: 'http://dropoutdao.xyz', picture: dropoutdao},
  { name: 'Miami Hack Week', url: 'https://www.miamihackweek.com', picture: miamiHackWeek},
  { name: 'Iron Hack', url: 'https://www.ironhack.com', picture: ironHack},
  { name: 'women in Web3', url: '' /*https://womeninweb3.com*/, picture: wiw3},
  { name: 'Blu', url: 'https://linktr.ee/Blu3_dao', picture: blu},
  { name: 'Web3 Familia', url: 'https://www.web3familia.com', picture: web3familia},
];

export const BOUNTY_PARTNERS = [
  { name: 'Principle Ventures', url: '', picture: principle},
];
