import logo from 'assets/images/activate-logo.svg'
import algorand from 'assets/images/brands/hero/algorand.svg'
import celo from 'assets/images/brands/hero/celo.svg'
import ethereum from 'assets/images/brands/hero/ethereum.svg'
import near from 'assets/images/brands/hero/near.svg'
import polygon from 'assets/images/brands/hero/polygon.svg'
import solana from 'assets/images/brands/hero/solana.svg'
import starknet from 'assets/images/brands/hero/starknet.svg'
import wormhole from 'assets/images/brands/hero/wormhole.svg'
import AppMenu from 'components/appMenu/AppMenu'
import './header.scss'

const Header = ({ showHero = true }) => {
  return (
    <header>
      <div className="top-bar">
        <div className="logo">
          <a href="/">
            <img width="125" height="28" alt="activate" src={logo} />
          </a>
        </div>
        <AppMenu />
      </div>
      {showHero &&
        <div className="hero">
          <div className="bg-layer__first"></div>
          <div className="bg-layer__second"></div>
          <div className="content">
            <span className="dates">
              <time dateTime="2022-05-18">MAY 18</time>-<time dateTime="2022-05-22">22</time> | MIAMI
            </span>
            <h1>Activate x Wormhole
            <span className="subtitle">Hackathon</span>
            <img className="hero-brands isolated" alt="wormhole" src={wormhole} height="40" width="192" />
            <span className="brands">
              <img className="hero-brands" alt="ethereum" src={ethereum} height="25" width="120" />
              <img className="hero-brands" alt="solana" src={solana} height="25" width="120" />
              <img className="hero-brands" alt="polygon" src={polygon} height="25" width="120" />
              <img className="hero-brands" alt="starknet" src={starknet} height="25" width="120" />
              <img className="hero-brands" alt="near" src={near} height="25" width="120" />
              <img className="hero-brands" alt="celo" src={celo} height="25" width="120" />
              <img className="hero-brands" alt="algorand" src={algorand} height="25" width="120" />
            </span>
            </h1>
            <a className="apply-button" target="_blank" href="https://forms.gle/ZBhuru9QZkgt7pv26" rel="noreferrer">
              Apply to Hack
              <span className="subtitle">$100k in prizes</span>
            </a>
          </div>
          {/* <FormBanner
            title="Join the first multi-chain hackathon"
            formUrl="https://forms.gle/ZBhuru9QZkgt7pv26"
            action="Apply to Hack"
          >
            We are partnering with the leading blockchain ecosystems 
            to bring together builders from all chains. Apply for free to
            start building.
          </FormBanner> */}
        </div>
      }
    </header>
  );
};

export default Header;
