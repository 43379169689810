import './schedule.scss'

const Schedule = () => {
  return (
    <>
      <h3>
        Schedule
      </h3>
      <h4>
        Find the details schedule <a href="https://activate-miami.notion.site/d6fbf56f706a4c1883c116338af2886b?v=27a2840009834de9b5da2fb05ef59022" target="_blank" rel="noreferrer" >here</a>
      </h4>
      <div className="schedule">
        <div className="weekday day1">
          <span className="weekday__short">Wed</span><span className="weekday__comp">Wednesday&nbsp;</span><span>18TH</span>
        </div>
        <div className="weekday day2">
          <span className="weekday__short">Thu</span><span className="weekday__comp">Thursday&nbsp;</span><span>19TH</span>
        </div>
        <div className="weekday day3">
          <span className="weekday__short">Fri</span><span className="weekday__comp">Friday&nbsp;</span><span>20TH</span>
        </div>
        <div className="weekday day4">
          <span className="weekday__short">Sat</span><span className="weekday__comp">Saturday&nbsp;</span><span>21TH</span>
        </div>
        <div className="weekday day5">
          <span className="weekday__short">Sun</span><span className="weekday__comp">Sunday&nbsp;</span><span>22TH</span>
        </div>
        <div className="bar hackathon">
          <div className="title">HACKATHON</div>
          <span>3 days for hackers to build projects</span>
        </div>
        <div className="bar ecosystem">
          <div className="title">ECOSYSTEMS DAYS</div>
          <span>Bootcamps to onboard builders into the sponsored ecosystems</span>
        </div>
        <div className="bar bounty">
          <div className="title">BOUNTY OFFICE HOURS</div>
          <span>Office Hours for sponsors to discuss their bounties</span>
        </div>
      </div>
    </>
  );
};

export default Schedule;
