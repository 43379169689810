import { Link } from "react-router-dom"

export const FAQS_MIAMI = [
  {question: 'Is Activate Miami happening in person? Can I participate remotely?', answer: (
    <>
      Activate Miami is an in-person event. However, we are also allowing remote participants, more info on this soon.
    </>
  )},
  {question: 'What are the ecosystem days?', answer: (
    <>
      Ecosystem days are happening right before the hackathon, on Wednesday and Thursday. Here, you will be able to learn more about building in each ecosystem. There will be hands-on workshops to give you the skills necessary to build a project during the Hackathon.
    </>
  )},
  {question: 'When does the hackathon start?', answer: (
    <>
      The main part of the Hackathon happens on Friday (20th) and Sunday (22nd). However, you can start working on your project on Wednesday (18th).
    </>
  )},
  {question: 'Do I need to participate in the 5 days of the event?', answer: (
    <>
      No, you can just participate in the hackathon part, Friday through Sunday.
    </>
  )},
  {question: 'How does the hackathon work?', answer: (
    <>
      During the hackathon, teams can build projects within one of the 4 tracks. On sunday, the projects are evaluated by our judges. The best project in each track, win prizes.
    </>
  )},
  {question: 'Who can participate?', answer: (
    <>
      Activate is open to every builder interested in Web3.0. You don't need to be a developer to participate in the event. So, if you are a developer, designer, or have any other skills which can be used to contribute to a project, <a href="https://forms.gle/EsXTzJd7BW37QuGk7" target="_blank" rel="noreferrer">APPLY Here</a> 
    </>
  )},
  {question: 'I don\'t know how to code can I participate?', answer: (
    <>
      Yes, Activate Miami is open to every builder who can contribute to projects, independent of their ability to code.
    </>
  )},
  {question: 'Do you sponsor scholarships to participate in the event?', answer: (
    <>
      Some of our partners are open to sponsoring scholarships. If you need someone to sponsor you, <a href="https://forms.gle/31AfUzE5E8u2ARk49" target="_blank" rel="noreferrer">apply here</a>. At the end of the form, choose the option: "I need a scholarship"
    </>
  )},
  {question: 'When will I know if I\'m accepted?', answer: (
    <>
      The first batch of acceptances will be sent out on the 1st of May.
    </>
  )},
  {question: 'How much does it cost to attend Activate Miami?', answer: (
    <>
      Activate Miami is free to attend for every participant.
    </>
  )},
  {question: 'How many people can be on a team?', answer: (
    <>
      Teams are limited to 6 members. We decided to make teams a bit bigger to incentivize less experienced builders to partner with experienced teams.
    </>
  )},
  {question: 'How does judging work?', answer: (
    <>
      Please check <Link to="/miami/prizes">here</Link>
    </>
  )},
  {question: 'What are the prizes for the winning teams?', answer: (
    <>
      <p>
        We have $25.000 in prizes to give to the:
      </p>
      <ul>
        <li>The best projects built in each chain</li>
        <li>The best projects in each track</li>
        <li>The best projects impact bounties.</li>
      </ul>
      <p>
        You can find more information <Link to="/miami/prizes">here</Link>
      </p>
    </>
  )},
  {question: 'I have a question not listed here. Who can I ask?', answer: (
    <>
      You can reach out to the Activate team at <a href="mailto:info@activate.build">info@activate.build</a>
    </>
  )},
  {question: 'How can I apply to volunteer?', answer: (
    <>
      You can apply to volunteer on <a href="https://forms.gle/TdyT1TZbqQyUz1747" target="_blank" rel="noreferrer">this form</a>. Select the "Volunteer" option.
    </>
  )},
  {question: 'How can I apply to mentor or judge?', answer: (
    <>
      You can apply to be a mentor or a judge on <a href="https://forms.gle/TdyT1TZbqQyUz1747" target="_blank" rel="noreferrer">this form</a>. Select the "Mentor" or "Volunteer" option.
    </>
  )},
];
















