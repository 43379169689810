import { Link } from 'react-router-dom'
import './prizes.scss'

const Prizes = () => {
  return (
    <>
      <h3 id="prizes">
        Prizes
      </h3>
      <h4 className="prizes-subtitle">Win $278k in prizes + bounties</h4>
      <p>
        Earn prizes by participating in tracks, building cross-chain solutions, impacting local communities, and more!
      </p>
      <h4 className="prizes-subtitle">Find <Link to="/miami/prizes">more here</Link></h4>
      <div className="prizes">
        <div className="prize">
          <div className="prize-title title1">
            <span>Ecosystem</span> <span>Prizes</span>
          </div>
          <div className="prize-content">
            Best projects developed on each of the sponsored ecosystems
            <h3>$60k in Prizes</h3>
          </div>
        </div>
        <div className="prize">
          <div className="prize-title title2">
            <span>Tracks</span>
          </div>
          <div className="prize-content">
            Best projects developed on each track
            <h3>$30k in Prizes</h3>
          </div>
        </div>
        <div className="prize">
          <div className="prize-title title3">
            <span>Sponsor</span> <span>Bounties</span>
          </div>
          <div className="prize-content">
            <a href="https://www.notion.so/activate-miami/Bounties-72425e1ed6e249f6934bba4e7b884b6a" target="_blank" rel="noreferrer" >Bounties</a> submitted by the sponsors
            <h3>Prizes $173,500 in Bounties</h3>
          </div>
        </div>
        <div className="prize">
          <div className="prize-title title4">
            <span>Special</span> <span>Bounties</span>
          </div>
          <div className="prize-content">
            Bring value to local communities
            <h3>$15k in Bounties</h3>
          </div>
        </div>
      </div>
    </>
  );
};

export default Prizes;
