import { TRACKS } from 'constants/tracks'
import Track from './Track'
import './tracks.scss'

const Tracks = () => {
  return (
    <div className="tracks">
      {TRACKS.map(track => <Track key={track.title} image={track.image} title={track.title}>{track.content}</Track>)}
    </div>
  );
};

export default Tracks;
