import activateMiami from 'assets/images/activate-miami.jpg'
import Section from 'components/section/Section'
import { Helmet } from 'react-helmet-async'
import './prizes-miami.scss'

const PrizesMiami = () => {
  return (
    <div className="prizes-page">
      <Helmet>
          <title>Activate x Wormhole Prizes, Bounties & Judging</title>
          <link rel="canonical" href="http://www.activate.build/miami/prizes" />
          <meta name="description" content="Prizes for the Activate Miami multi-chain hackathon" />
          <meta property="og:description" content="Prizes for the Activate Miami multi-chain hackathon" />
          <meta property="og:image" content={activateMiami} />
          <meta property="og:title" content="Prizes - Activate x Wormhole Miami" />
          <meta property="og:url" content="http://www.activate.build/miami/prizes" />
          <meta property="twitter:title" content="Prizes - Activate x Wormhole Miami" />
          <meta property="twitter:description" content="Prizes for the Activate Miami multi-chain hackathon" />
      </Helmet>
      <Section type="columns" className="prizes-content">
        <div className="centred">
          <h1>Activate x Wormhole Prizes, Bounties & Judging</h1>
          <p className="sub-text">
            Every team participating in the Hackathon will have the chance to submit their project to be judged and have the chance to be awarded different types of prizes and bounties.
          </p>
          <p className="sub-text">
            The submission will be done through the Dorahacks platform: <a href="https://dorahacks.io/hackathon/25/" target="_blank" rel="noreferrer">https://dorahacks.io/hackathon/25/</a>
          </p>
          <p className="sub-text">
            Activate X Wormhole Miami has a total main prize & bounty pool of $105,000.00 adding to an additional $173,500 of sponsor bounties, which means there is a grand total of:
          </p>
          <p className="sub-text">
            <strong>$278,500.00 in prizes & bounties</strong>
          </p>
          <p className="sub-text">
            Sponsor Bounties can be found here: <a href="https://www.notion.so/activate-miami/Bounties-72425e1ed6e249f6934bba4e7b884b6a" target="_blank" rel="noreferrer">Bounties</a>
          </p>
        </div>
        <h2>
          Types of Prizes & Bounties:
        </h2>
        <ul>
          <li>
            Track Prizes
            <ul>
              <li>
                Every project that is submitted for judging needs to choose one single track from the following:
                <ul>
                  <li>DeFi</li>
                  <li>Metaverse</li>
                  <li>NFT</li>
                  <li>Infrastructure</li>
                </ul>
              </li>
              <li>The project’s track defines which track prize the project is eligible to receive</li>
              <li>The best 3 projects in each track will win $2,500.00 (total track pool of $7,500), to be split by the team.</li>
            </ul>
          </li>
          <li>
            Ecosystem Prizes
            <ul>
              <li>A project that is built using a particular ecosystem is eligible to win that ecosystem's prize.</li>
              <li>If a project is built using multiple ecosystems, it is eligible to win multiple prizes (one per ecosystem).</li>
              <li>
                There are 8 ecosystems in total:
                <ul>
                  <li>Ethereum</li>
                  <li>Solana</li>
                  <li>Polygon</li>
                  <li>StarkNet</li>
                  <li>NEAR</li>
                  <li>CELO</li>
                  <li>Algorand</li>
                  <li>Gnosis Chain</li>
                </ul>
              </li>
              <li>The best 3 projects in each ecosystem will win $2,500.00 ($7,500 per ecosystem), to be split by the team.</li>
            </ul>
          </li>
          <li>
            Sponsor Bounties
            <ul>
              <li>Each sponsor has a chance to promote their own bounties that are designed to incentivize teams to leverage the technology and/or ecosystem goals of each sponsor</li>
              <li>The sponsors define the criteria for eligibility and judging for each of their bounties</li>
              <li>There is a total of $173,500 of sponsor bounties available</li>
              <li>Sponsor bounties can be found here: <a href="https://www.notion.so/activate-miami/Bounties-72425e1ed6e249f6934bba4e7b884b6a" target="_blank" rel="noreferrer">Bounties</a></li>
            </ul>
          </li>
          <li>
            Special Bounties
            <ul>
            <li>Any team can be eligible to win a special bounty as long as their project meets each bounty’s specific criteria</li>
            <li>
              There are 3 types of special bounties
              <ul>
                <li>Miami Local Impact bounty</li>
                <li>Inclusion Impact bounty</li>
                <li>UI/UX award bounty</li>
              </ul>
            </li>
            <li>The best 2 projects for each special bounty will get $2,500.00 ($5,000.00 per special bounty), to be split by the team.</li>
          </ul>
          </li>
        </ul>
        <h2>Judging Criteria and Process:</h2>
        <p>Activate Miami x Wormhole follows a track neutral method of prize attribution and judging. While each submitted project is part of one of the four different tracks, projects are judged based on the same general criteria.</p>
        <p>To ensure that projects are still fairly judged within their own track, judging group members are selected in a way that each group includes at least one person specialized in each of the four tracks.</p>
        <h3>Track Prize Criteria:</h3>
        <ul>
          <li><em>Technology</em>: How hard was the project to build, and how well does the project leverage technology.</li>
          <li><em>Originality</em>: How original is the project? Is this something completely new or are there other existing instances of the same type of projection and/or execution?</li>
          <li><em>Practicality</em>: How useful is this submission in the real world? outside of the crypto community? Or, if the project were to be specifically targeted to the crypto community, how correctly targeted is it?</li>
          <li><em>WOW Factor</em>: What else is there about the submission? Has this project and/or team defied expectations, based on timing and efforts?</li>
        </ul>
        <h3>Submission & Track Prize Judging Process:</h3>
        <ul>
          <li>Track prizes judging will be executed by the general judging groups</li>
          <li>Each project will be assigned a judging group to whom the team will pitch their submission to</li>
          <li>Projects can be submitted through the <a href="https://dorahacks.io/hackathon/25/" target="_blank" rel="noreferrer">Dorahacks platform</a> until Sunday, May 22nd at 10 am.</li>
          <li>The teams will then receive the room name and time to pitch their project to the judges</li>
          <li>All communication will be conducted through discord</li>
          <li>
            After projects have been submitted the teams will have a chance to pitch to their assigned judging group
            <ul>
              <li>Pitches can be no longer than 5 minutes</li>
              <li>There is a maximum of 3 minutes of Q&A</li>
              <li>Each team also has to submit a video of the same pitch</li>
            </ul>
          </li>
          <li>All 3 top projects will each receive a $2,500.0 prize to be split amongst the team</li>
        </ul>
        <h3>Ecosystem Prize judging process:</h3>
        <ul>
          <li>Once every project has gone through the track prize judging process the top 5 scoring projects building on each ecosystem become eligible for that ecosystem prize</li>
          <li>An ecosystem dedicated judging team will then pick the 3 best projects</li>
          <li>Each of the top 3 projects will receive a $2,500.00 prize to be split amongst the team</li>
          <li>The same team can receive multiple ecosystem prizes if their project is built on more than one ecosystem</li>
        </ul>
        <h3>Special Bounties criteria & judging process:</h3>
        <ul>
          <li>Once every project has gone through the track prize judging process, a special bounty dedicated judging team will pick the 2 best projects per special bounty</li>
          <li>To be eligible for a special bounty teams need to actively select that they want to participate in this bounty during the submission process</li>
          <li>
            Each special bounty has its own criteria:
            <ul>
              <li>
                Miami Local Impact Bounty
                <ul>
                  <li>Most innovative & impactful projects addressing a problem that impacts the local community of Miami</li>
                </ul>
              </li>
              <li>
                Inclusion Impact Bounty
                <ul>
                  <li>Most innovative & impactful projects addressing inclusion challenges</li>
                </ul>
              </li>
              <li>
                UI/UX special bounty
                <ul>
                  <li>Projects with the best overall UI/UX</li>
                </ul>
              </li>
            </ul>
          </li>
          <li>The best two projects for each special bounty will be awarded $2,500.00 (total of $5,000 per special bounty)</li>
        </ul>
        <h3>Sponsor Bounties judging process:</h3>
        <ul>
          <li>Once every project has gone through the track prize judging process, sponsors will be able to award their bounties</li>
          <li>To be eligible for a special bounty, teams need to actively select that they want to participate in this bounty during the submission process. This will be done on the <a href="https://dorahacks.io/hackathon/25/" target="_blank" rel="noreferrer">Dorahacks platform</a>.</li>
          <li>Sponsor bounties are awarded according to criteria defined by each sponsor available in each sponsor discord channel</li>
        </ul>
        <h2>Detailed List of all Prizes & bounties ($283,000.00):</h2>
        <h3>Track Prizes ($30,000.00 total):</h3>
        <ul>
          <li>
            DeFi ($7,500.00 total)
            <ul><li>$2,500.00 for each of the top 3 projects in each track</li></ul>
          </li>
          <li>
            Metaverse ($7,500.00 total)
            <ul><li>$2,500.00 for each of the top 3 projects in each track</li></ul>
          </li>
          <li>
            NFT ($7,500.00 total)
            <ul><li>$2,500.00 for each of the top 3 projects in each track</li></ul>
          </li>
          <li>
            Infrastructure ($7,500.00 total)
            <ul><li>$2,500.00 for each of the top 3 projects in each track</li></ul>
          </li>
        </ul>
        <h3>Ecosystem Prizes ($60,000.00 total):</h3>
        <ul>
          <li>
            Ethereum ($7,500.00 total)
            <ul><li>$2,500.00 for each of the top 3 projects in each ecosystem</li></ul>
          </li>
          <li>
            Solana ($7,500.00 total)
            <ul><li>$2,500.00 for each of the top 3 projects in each ecosystem</li></ul>
          </li>
          <li>
            Polygon ($7,500.00 total)
            <ul><li>$2,500.00 for each of the top 3 projects in each ecosystem</li></ul>
          </li>
          <li>
            StarkNet ($7,500.00 total)
            <ul><li>$2,500.00 for each of the top 3 projects in each ecosystem</li></ul>
          </li>
          <li>
            NEAR ($7,500.00 total)
            <ul><li>$2,500.00 for each of the top 3 projects in each ecosystem</li></ul>
          </li>
          <li>
            CELO ($7,500.00 total)
            <ul><li>$2,500.00 for each of the top 3 projects in each ecosystem</li></ul>
          </li>
          <li>
            Algorand ($7,500.00 total)
            <ul><li>$2,500.00 for each of the top 3 projects in each ecosystem</li></ul>
          </li>
          <li>
            Gnosis Chain ($7,500.00 total)
            <ul><li>$2,500.00 for each of the top 3 projects in each ecosystem</li></ul>
          </li>
        </ul>
        <h3>Special Bounties ($15,000.00):</h3>
        <ul>
          <li>
            Miami Local Impact bounty ($5,000.00)
            <ul><li>$2,500.00 for each of the top 2 projects in each impact bounty</li></ul>
          </li>
          <li>
            Inclusion Impact bounty ($5,000.00)
            <ul><li>$2,500.00 for each of the top 2 projects in each impact bounty</li></ul>
          </li>
          <li>
            UI/UX award bounty ($5,000.00)
            <ul><li>$2,500.00 for each of the top 2 projects in each impact bounty</li></ul>
          </li>
        </ul>
        <h3>Sponsor Bounties ($173,500):</h3>
        <a href="https://activate-miami.notion.site/activate-miami/Bounties-72425e1ed6e249f6934bba4e7b884b6a" target="_blank" rel="noreferrer">https://activate-miami.notion.site/activate-miami/Bounties-72425e1ed6e249f6934bba4e7b884b6a</a>
      </Section>
    </div>
  );
};

export default PrizesMiami;
