import bullImg from 'assets/images/bull.png'
import venueMap from 'assets/images/venue-map.jpg'
import './venue.scss'

const Venue = () => {
  return (
    <>
      <h3>
        Venue
      </h3>
      <div className="venue">
        <img
          src={bullImg}
          alt="ideacenter"
        />
        <div>
          <h4>The Idea Center - Miami Dade College</h4>
          <p>Wolfson Campus<br />
          315 Northeast 2nd Avenue Building 8, 5th Floor, Miami</p>
          <a className="apply-button alt" target="_blank" href="https://g.page/ideacentermdc?share" rel="noreferrer">Get Directions</a>
        </div>
      </div>
      <div className="venue">
        <div>
          <ol>
            <li>WORMHOLE HALL
              <ul>
                <li>Hacking Area 1</li>
                <li>Sponsor Booths</li>
                <li>Breakfast, Lunch & Dinner</li>
              </ul>
            </li>
            <li>BIT CENTER
              <ul>
                <li>Hackathon Registration</li>
                <li>HerDAO Workshop Room</li>
                <li>DoraHacks Workshop Room</li>
              </ul>
            </li>
            <li>IDEA CENTER
              <ul>
                <li>Hacking Area 2</li>
                <li>Encode Workshop Room</li>
              </ul>
            </li>
            <li>LIGHTSHIFT AUDITORIUM
              <ul>
                <li>Closing Ceremony</li>
              </ul>
            </li>
            <li>HACKER PARKING</li>
            <li>CRYPTO BULL</li>
            <li>305 AUDITORIUM
              <ul>
                <li>Opening Ceremony</li>
              </ul>
            </li>
          </ol>
        </div>
        <a className="link-image" href={venueMap} target="_blank"  rel="noreferrer">
          <img src={venueMap} alt="Map of the Venue" />
        </a>
      </div>
    </>
  );
};

export default Venue;
