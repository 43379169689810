import './section.scss'

const Section = ({ type = '', children, className = '' }) => {
  return (
    <section className={`content-section ${type} ${className}`}>
      {children}
    </section>
  );
}

export default Section;
