import logo from 'assets/images/activate-logo-inverted.svg'
import './footer.scss'

const Footer = () => {
  return (
    <footer>
      <div className="footer-content">
        <div className="footer-columns">
          <div className="left">
            <img width="150" alt="activate" src={logo} height="34" />
            <p>
              Blockchain technology has the potential<br />
              to transform the world.
            </p>
          </div>
          <div className="right">
            <p>Connect us</p>
            <div className="buttons">
              <a className="icon-a twitter" href="https://twitter.com/activate_build" target="_blank" rel="noreferrer">twitter</a>
              <a className="icon-a telegram" href="https://t.me/+81B5BfddXt03OTQ0" target="_blank" rel="noreferrer">telegram</a>
              <a className="icon-a discord" href="https://discord.gg/QhYnmqbjUM" target="_blank" rel="noreferrer">discord</a>
            </div>
          </div>
        </div>
        <div className="copyright">
          Copyright © 2022 Activate
        </div>
      </div>

    </footer>
  );
};

export default Footer;
