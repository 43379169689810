import './brands-wall.scss'

const BrandsWall = ({ brands, title }) => {
  return (
    <div className="brands-wall">
      <div className="header-title">
        <h2>
          {title}
        </h2>
      </div>
      <div className="brands-list">
        {brands.map(brand => (
          <a key={brand.name} className={`brand${!brand.url ? ' no-link' : ''}`} target="_blank" {...brand.url ? {href: brand.url} : {}} rel="noreferrer">
            <img alt={brand.name} src={brand.picture} height="130" width="250" />
          </a>
        ))}
      </div>
    </div>
  );
};

export default BrandsWall;
