import activateMiami from 'assets/images/activate-miami.jpg'
import BrandsWall from 'components/brandsWall/BrandsWall'
import Clear from 'components/clear/Clear'
import Judges from 'components/judges/Judges'
import Prizes from 'components/prizes/Prizes'
import Schedule from 'components/schedule/Schedule'
import Section from 'components/section/Section'
import Tracks from 'components/tracks/Tracks'
import Venue from 'components/venue/Venue'
import { BOUNTY_PARTNERS, ECOSYSTEMS_SPONSORS, MAIN_ORGANIZERS, PARTNERS, SUPPORTING_ORGANIZERS } from 'constants/brands'
import { JUDGES } from 'constants/judges'
import { Helmet } from 'react-helmet-async'
import './landing-miami.scss'

const LandingMiami = () => {
  return (
    <>
      <Helmet>
          <title>Activate x Wormhole Miami</title>
          <link rel="canonical" href="http://www.activate.build/miami" />
          <meta name="description" content="Activate x Wormhole Miami is the first multi-chain hackathon bringing the top ecosystems together. Come hack with Wormhole, Ethereum, Solana, Near, StarkNet, Celo" />
          <meta property="og:title" content="Activate x Wormhole Miami" />
          <meta property="og:url" content="http://www.activate.build/miami" />
          <meta property="og:description" content="Activate x Wormhole Miami is the first multi-chain hackathon bringing the top ecosystems together. Come hack with Wormhole, Ethereum, Solana, Near, StarkNet, Celo" />
          <meta property="og:image" content={activateMiami} />
          <meta property="twitter:title" content="Activate x Wormhole Miami" />
          <meta property="twitter:description" content="Activate x Wormhole Miami is the first multi-chain hackathon bringing the top ecosystems together. Come hack with Wormhole, Ethereum, Solana, Near, StarkNet, Celo" />
      </Helmet>
      <Section type="columns">
        <Venue />
      </Section>
      <Section>
        <h3>
          What is <br />Activate x Wormhole
        </h3>
        <div className="arrow">
          <div></div>
        </div>
        <div className="section-p">
          <p>
            Activate x Wormhole is the first multi-chain hackathon bringing together leading blockchain ecosystems for a week of learning, building, and exploring web 3.
          </p>
          <p>
            Apply to attend Activate x Wormhole to access:
          </p>
          <ul>
            <li>
              Ecosystem days, mentorship, and bootcamps
            </li>
            <li>
              Project tracks & bounties focused on cross-chain and impact ideas
            </li>
            <li>
              Collaboration with different kinds of builders, from engineers to designers
            </li>
            <li>
              A new network in web 3
            </li>
          </ul>
        </div>
      </Section>
      <Clear />
      <Section>
        <h3>
          New to<br />Web3?
        </h3>
        <div className="arrow">
          <div></div>
        </div>
        <div className="section-p">
          <p>
            Web 3 is one of the fastest growing emerging tech sectors. What does this mean for students and builders? A whole new world of opportunity.
          </p>
          <p>
            At Activate x Wormhole, new entrants to web 3 will kick-start new projects, explore job opportunities, and receive mentorship from industry leaders. Whether want to be a designer, developer, or community builder, Activate x Wormhole is designed to help you grow and experiment with blockchain together. 
          </p>
        </div>
      </Section>
      <Clear />
      <Section>
        {/* <FormBanner 
          title="Open call for mentors, judges and volunteers" 
          formUrl="https://forms.gle/yv867TE3Fy3b6A9GA"
          action="Register"
        >
          If you want to contribute to Activate x Wormhole as a mentor, judge or volunteer, register now.
        </FormBanner> */}
      </Section>
      <Clear />
      <Section type="columns">
        <Prizes />
      </Section>
      <Clear />
      <Section type="columns">
        <Schedule />
      </Section>
      <Clear />
      <Section>
        <div className="title-content">
          <h3>
            Tracks
          </h3>
          <p>
            Build your team and choose your track to experiment in web 3 and earn prizes.
          </p>
        </div>
        <div className="arrow">
          <div></div>
        </div>
        <Tracks />
      </Section>
      <Clear />
      <Section>
        <Judges judges={JUDGES} />
      </Section>
      <Section>
        <BrandsWall title="Ecosystems and Sponsors" brands={ECOSYSTEMS_SPONSORS} />
      </Section>
      <Section>
        {/* <FormBanner 
          title="Activate x Wormhole" 
          formUrl="https://forms.gle/AvPbkc92uzrY73v77"
          action="BECOME A SPONSOR"
        >
          <time dateTime="2022-05-18">MAY 18</time>-<time dateTime="2022-05-22">22</time>
        </FormBanner> */}
      </Section>
      <Section>
        <BrandsWall title="Main Organizers" brands={MAIN_ORGANIZERS} />
      </Section>
      <Section>
        <BrandsWall title="Supporting Organizers" brands={SUPPORTING_ORGANIZERS} />
      </Section>
      <Section>
        <BrandsWall title="Partners" brands={PARTNERS} />
      </Section>
      <Section type="last">
        <BrandsWall title="Bounty Partners" brands={BOUNTY_PARTNERS} />
      </Section>
      {/* <Section type="last">
        <h3>
          What is<br />Activate?
        </h3>
        <div className="arrow">
          <div></div>
        </div>
        <div className="section-p">
          <p>
            Activate is a global organization of blockchain builders fostering a multi-chain and fully inclusive crypto industry.
          </p>
          <p>
            Activate Offers Learners:
          </p>
          <ul>
            <li>
              Web 3 education opportunities
            </li>
            <li>
              Hackathons and bootcamps in cities across the globe
            </li>
            <li>
              A distributed community of passionate builders
            </li>
          </ul>
        </div>
      </Section> */}
      <Clear />
    </>
  );
};

export default LandingMiami;
