import akash from 'assets/images/judges/akash.avif'
import akshi from 'assets/images/judges/akshi.avif'
import alex from 'assets/images/judges/alex.avif'
import amit from 'assets/images/judges/amit.avif'
import anthony from 'assets/images/judges/anthony.avif'
import antoni from 'assets/images/judges/antoni.avif'
import blake from 'assets/images/judges/blake.avif'
import camila from 'assets/images/judges/camila.avif'
import eash from 'assets/images/judges/eash.avif'
import erin from 'assets/images/judges/erin.avif'
import eryka from 'assets/images/judges/eryka.avif'
import gal from 'assets/images/judges/gal.avif'
import jonathan from 'assets/images/judges/jonathan.avif'
import ken from 'assets/images/judges/ken.avif'
import laura from 'assets/images/judges/laura.avif'
import mattia from 'assets/images/judges/mattia.avif'
import melissa from 'assets/images/judges/melissa.avif'
import novell from 'assets/images/judges/novell.avif'
import sachi from 'assets/images/judges/sachi.avif'
import samantha from 'assets/images/judges/samantha.avif'
import shawn from 'assets/images/judges/shawn.avif'
import sunny from 'assets/images/judges/sunny.avif'
import thomas from 'assets/images/judges/thomas.avif'
import thy from 'assets/images/judges/thy.avif'
import tracey from 'assets/images/judges/tracey.avif'
import tricia from 'assets/images/judges/tricia.avif'
import will from 'assets/images/judges/will.avif'
import will2 from 'assets/images/judges/will2.avif'
import zaid from 'assets/images/judges/zaid.avif'


export const JUDGES = [
  { name: 'Akshi Federici', title: 'Kraken Ventures', photo: akshi},
  { name: 'Camila Ramos', title: 'Edge and Node', photo: camila},
  { name: 'Eryka Gemma', title: 'Founder at Timelock Ventures', photo: eryka},
  { name: 'Jonathan H', title: 'Republic Crypto', photo: jonathan},
  { name: 'Ken Timsit', title: 'Cronos Chain', photo: ken},
  { name: 'Sachi Kamiya', title: 'VP of investments, Polygon Ecosystem Fund', photo: sachi},
  { name: 'Sunny Aggarwal', title: 'Osmosis', photo: sunny},
  { name: 'Thomas Klocanas', title: 'Block Tower', photo: thomas},
  { name: 'Thy-Diep Ta', title: 'H.E.R Dao & Unit Network', photo: thy},
  { name: 'Blake Gao', title: 'Partner of Principle', photo: blake},
  { name: 'Melissa Zhang', title: 'CTO, Co-Founder of Bonfire', photo: melissa },
  { name: 'Samantha BohBot', title: ' Director of Platform at DCG', photo: samantha },
  { name: 'Will Wang', title: 'TKX', photo: will },
  { name: 'Laura Gaviria Halaby', title: 'Softbank Operating Group', photo: laura },
  { name: 'Anthony Beaumont', title: 'CEO and co-founder Encode Club', photo: anthony },
  { name: 'Alex Witt', title: 'Celo Foundation CFO', photo: alex },
  { name: 'Shawn Wilkinson', title: 'Founder and CSO at Storj Labs Inc.', photo: shawn },
  { name: 'Tracey Bowen', title: 'Founder of H.E.R Dao', photo: tracey },
  { name: 'Tricia Wang', title: 'Co-founder + Lead at CRADL', photo: tricia },
  { name: 'Will Wolf', title: 'Investment Partner @ Polychain', photo: will2 },
  { name: 'Mattia Mrvosevic', title: 'Partner at Eterna Capital', photo: mattia },
  { name: 'Zaid Rahman', title: 'Partner at 305 Ventures', photo: zaid },
  { name: 'Novell Loh', title: 'Founder of Blu3DAO', photo: novell },
  { name: 'Antoni Zolciak', title: 'Co-founder at Aleph Zero', photo: antoni },
  { name: 'Akash Koshla', title: 'Co-Founder of EVMOS', photo: akash },
  { name: 'Eash Aggarwal', title: 'Quantative analyst at BlockTower', photo: eash },
  { name: 'Erin Lee', title: 'Front end engineer at Coinbase', photo: erin },
  { name: 'Amit Chu', title: 'Finance & Accounting Partner at Celo', photo: amit },
  { name: 'Gal Ron', title: 'Product Manager & Blockchain Researcher at StarkWare', photo: gal },
];
