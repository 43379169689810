import './faq.scss'

const Faq = ({ question, children, onClick, open }) => {
  
  const handleOnFaqClick = () => {
    onClick(open ? undefined : question);
  }
  
  return (
    <button className={`faq-element ${open ? 'visible': 'hidden'}`} onClick={handleOnFaqClick}>
      <div className="question">{question}</div>
      <div className={`faq-answer ${open ? 'visible': 'hidden'}`}><div className="content">{children}</div></div>
    </button>
  );
};

export default Faq;
